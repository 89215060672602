<template>
  <footer>
    <b-container class="bv-example-row px-5">
      <b-row class="bottom-line top-row">
        <b-col lg="3">
          <div class="tel-container">
            <div class="text-green fs-13">
              {{ $t('footerTitle1') }}
            </div>
            <div>
              <b-link class="fs-20"
                      :href="$t('companyTelephoneHref')"
              >
                {{  $t('companyTelephone') }}
              </b-link>
            </div>
            <div>
              <b-link class="fs-20"
                      :href="$t('companySecondTelephoneHref')"
              >
                {{  $t('companySecondTelephone') }}
              </b-link>
            </div>
          </div>
        </b-col>
        <b-col lg="3">
          <div>
            <div class="text-green fs-13">
              {{ $t('footerTitle2') }}
            </div>
            <b-link class="fs-20" :href="$t('email1Href')" v-html="$t('email1')"></b-link>
          </div>
        </b-col>
        <b-col lg="3">
          <div>
            <div class="text-green fs-13">
              {{ $t('footerTitle3') }}
            </div>
            <div>
              <span class="text-white fs-20">{{ $t('monToFri') }} 8:00-17:00</span>
            </div>
            <div>
              <span class="text-white fs-20">{{ $t('saturday') }} 8:00-14:00</span>
            </div>
          </div>
        </b-col>
        <b-col lg="3">
          <div>
            <div class="text-green fs-13">
              {{ $t('footerTitle4') }}
            </div>
            <nuxt-link class="fs-20" to="/help-center">{{ $t('helpCenterLinkText') }}</nuxt-link>
          </div>
        </b-col>
      </b-row>
      <b-row class="bottom-line links-row">
        <b-col lg="3">
          <span class="footer-subtitle">{{ $t('holidayCottages') }}</span>
          <b-list-group>
            <b-list-group-item>{{ $t('companyOwner') }}</b-list-group-item>
            <b-list-group-item>{{ $t('companyStreet') }}</b-list-group-item>
            <b-list-group-item>{{ $t('companyPostcodeAndCity') }}</b-list-group-item>
            <b-list-group-item>
              <b-link class="localization-link"
                      :href="localizationLink">
                {{ $t('mapLinkText') }}
              </b-link>
            </b-list-group-item>
            <b-list-group-item class="social-links">
              <b-link :href="youtubeLink">
                <b-button class="circle-btn" pill variant="success" aria-label="YouTube Link">
                  <fa :icon="['fab', 'youtube']" style="font-size: 17px; color: #ffffff; margin: 0 0 -1px -1px"/>
                </b-button>
              </b-link>
              <b-link :href="facebookLink">
                <b-button class="circle-btn" pill variant="success" aria-label="Facebook Link">
                  <fa :icon="['fab', 'facebook-f']" style="font-size: 17px; color: #ffffff;; margin: 0 0 -1px 0"/>
                </b-button>
              </b-link>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col lg="3">
          <span class="footer-subtitle">{{ $t('footerSubtitle1') }}</span>
          <b-list-group>
            <b-list-group-item v-for="(link, linkIndex) in shopLinks" :key="linkIndex">
              <nuxt-link :to="link.href" v-html="link.name"></nuxt-link>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col lg="6" class="custom-padding-1">
              <span class="footer-subtitle">{{ $t('footerSubtitle2') }}</span>
              <b-list-group>
                <b-list-group-item v-for="(link, linkIndex) in userAccountLinks" :key="linkIndex">
                  <nuxt-link :to="link.href" v-html="link.name"></nuxt-link>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col lg="6" class="custom-padding-2">
              <span class="footer-subtitle">{{ $t('footerSubtitle3') }}</span>
              <b-list-group>
                <b-list-group-item v-for="(link, linkIndex) in informationLinks" :key="linkIndex">
                  <nuxt-link :to="link.href" v-html="link.name"></nuxt-link>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" class="custom-padding-3">
              <anonymous-newsletter/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="bottom-row fs-13 d-flex justify-content-between align-items-center">
        <div>
          <fa :icon="['fas', 'cookie-bite']"
              style="font-size: 14px; color: rgba(255, 255, 255, 0.6); margin-right: 7px"/>
          <span>{{ $t('cookiesInfo') }}</span>
          <nuxt-link to="/article/privacy-policy" class="cookies-link">
            cookies
          </nuxt-link>
          .
        </div>
        <div class="text-right">
          <nuxt-link to="/article/privacy-policy" class="ml-3">{{ $t('privacyPolice') }}</nuxt-link>
          <nuxt-link to="/article/user-agreement" class="ml-3">{{ $t('shopRegulations') }}</nuxt-link>
        </div>
      </div>
    </b-container>
  </footer>
</template>

<script>
  import AnonymousNewsletter from '~/components/UtilityComponents/AnonymousNewsletter'

  export default {
    name: "LayoutFooter",
    components: {
      AnonymousNewsletter
    },
    data() {
      return {
        shopLinks: [
          {name: this.$t('myMenu.basket'), href: '/basket'},
          {name: this.$t('myMenu.returnsAndComplaints'), href: '/my/complaints'},
          {name: this.$t('myMenu.register'), href: '/register'}
        ],
        userAccountLinks: [
          {name: this.$t('myMenu.yourOrders'), href: '/my/orders'},
          {name: this.$t('myMenu.contactDetails'), href: '/my/profile-data'},
          {name: this.$t('myMenu.deliveryAddresses'), href: '/my/addresses'}
        ],
        informationLinks: [
          {name: this.$t('myMenu.contact'), href: '/contact'},
          {name: this.$t('myMenu.informationalArticles'), href: '/information-articles'},
          {name: this.$t('myMenu.news'), href: '/articles-list'},
          {name: this.$t('myMenu.aboutCompany'), href: '/article/about-us'},
          {name: this.$t('terms'), href: '/article/ows'}
        ],
        localizationLink: "https://www.google.com/maps/place/Digital+Holding+Sp.+z+o.+o./@50.8762306,18.4219979,15z/data=!4m2!3m1!1s0x0:0x84005638d979e9ac?sa=X&ved=2ahUKEwimlPTj5_WCAxUWQ_EDHQtyDgkQ_BJ6BAhDEAA",
        facebookLink: "https://www.facebook.com/DigitalHoldingPL/",
        youtubeLink: "https://www.youtube.com/channel/UClzVVw17xc2-9Uin-0m-6LQ"
      }
    }
  }
</script>

<style lang="scss" scoped>
  $bgColor: #053952;
  $borderColor: rgba(255, 255, 255, 0.1);
  $textColor1: #ffffff;
  $textColor2: #1fae52;
  $textColor3: rgba(255, 255, 255, 0.8);
  $textColor4: rgba(255, 255, 255, 0.6);
  $textColor5: rgba(255, 255, 255, 0.4);
  $circleBtnBg: linear-gradient(270deg, rgba(#1fae52, 0.8) 0%, rgba(#42d78a, 0.8) 100%);
  $inputBg: rgba(255, 255, 255, 0.1);

  footer {
    background-color: $bgColor;
    color: $textColor1;

    .custom-padding-3{
      padding-top: 50px;
      padding-right: 0;
    }

    .footer-subtitle {
      display: inline-block;
      font-size: 1.3rem;
      margin-bottom: 16px;
    }

    .top-row {
      border-color: $borderColor;
      padding: 20px 0;

      a {
        color: $textColor1;

        &:hover {
          color: $textColor2;
        }
      }

      .tel-container {
        display: flex;
        flex-direction: column;
      }
    }

    .links-row {
      border-color: $borderColor;
      padding: 29px 0 46px 0;

      .list-group-item {
        background-color: transparent;
        border: none;
        font-size: 1.3rem;
        letter-spacing: 0.46px;
        padding: 0 0 1rem 0;

        .localization-link {
          color: $textColor2;
          text-decoration: underline;

          &:hover {
            color: $textColor1;
          }
        }

        &.social-links {
          margin-top: 42px;
          padding-bottom: 0;

          .circle-btn {
            width: 33px;
            height: 32px;
            background-color: transparent;
            background-image: $circleBtnBg;
            border-radius: 50%;
            margin-right: 8px;
          }
        }

        a {
          color: $textColor3;

          &:hover {
            color: $textColor2;
          }
        }
      }
    }

    .bottom-row {
      color: $textColor5;
      padding: 20px 0;

      a {
        color: $textColor5;
        letter-spacing: 0.46px;

        &:hover {
          color: $textColor2;
        }
      }

      .ml-3 {
        margin-left: 3rem !important;
      }

      .cookies-link {
        text-decoration: underline;
      }
    }

    @media (max-width: 1200px) {
      > .bv-example-row {
        padding: 0 15px !important;
        max-width: 100%;

        .top-row .col-lg-3 a, .top-row .col-lg-3 span {
          font-size: 1.6rem;
        }
      }
    }

    @media (max-width: 992px) {
      .top-row, .links-row {
        flex-wrap: wrap;

        > :nth-child(1), > :nth-child(2) {
          margin-bottom: 10px;
        }

        .col-lg-3 {
          width: 50%;

          .social-links {
            margin: 10px 0 20px 0;
          }
        }

        .col-lg-6 .row .col-lg-6 {
          width: 50%;
        }
      }
    }

    @media (max-width: 805px) {
      .bottom-row {
        flex-direction: column;
        align-items: flex-start !important;

        > :first-child {
          margin-bottom: 5px;
        }

        .ml-3 {
          margin-left: 0 !important;
          margin-right: 2rem;
        }
      }
    }

    @media (max-width: 750px) {
      overflow: hidden;

      .row {
        margin: 0 !important;
      }

      > .bv-example-row {
        padding: 0 !important;
      }

      .bottom-row {
        padding: 20px 15px;
      }

      .custom-padding-1, .custom-padding-3 {
        padding-left: 0;
      }
    }

    @media (max-width: 535px) {
      .bottom-row > :first-child {
        font-size: 1.2rem;

        svg {
          display: none;
        }
      }
    }

    @media (max-width: 501px) {
      .top-row .col-lg-3, footer .links-row .col-lg-3 {
        width: 100%;
        margin-bottom: 20px;
      }

      .links-row {
        .col-lg-3 {
          width: 100%;
          margin-bottom: 20px;
        }

        .col-lg-6 .row .col-lg-6 {
          width: 100%;
          margin-bottom: 20px;
        }

        .custom-padding-2 {
          padding-left: 0;
        }
      }
    }

    @media(max-width: 340px) {
      .bottom-row .ml-3 {
        margin-right: 1rem;
      }
    }
  }
</style>
